@media print {
  body {
    background-color: #fff !important;
    font-family: 'SiyamRupali', Arial, sans-serif !important;
  }
  .header-row {
    font-size: 14px !important;
  }
  /* .certificate-row {
    font-size: 15px;
  } */
  /* .issue-date {
    font-size: 29px !important;
  } */
  /* .character-sonod .certificate-row {
    font-size: 16px !important;
  } */
  .sonod-no,
  .issue-date {
    font-size: 17px !important;
  }
  .sonod-title {
    margin-top: 5px;
  }
  .left-title .row {
    font-size: 15px;
  }
  .chairman {
    position: absolute;
    bottom: 200px;
    width: 100%;
    right: 30px;
  }
  .chairman-trade {
    position: absolute;
    bottom: 120px;
    width: 100%;
    right: 30px;
  }
  .chairman-area {
    width: 350px !important;
  }
  .certificate-verify {
    bottom: 55px !important;
    position: absolute;
    width: 100%;
  }
  /* .qr-code {
    text-align: center;
    position: absolute;
    bottom: 0 !important;
    left: 25%;
  } */
  .qr-code {
    padding-top: 13px;
  }
  .chairman-info {
    font-size: 15px;
    text-align: center;
    line-height: 1.5;
  }
  .certificate-footer {
    font-size: 12px;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .card-body {
    padding: 50px !important;
  }
  /* .print-area {
    border: 2px solid #000000 !important;
    height: 1020px !important;
    padding: 13px;
  } */
  .certificate-note {
    margin-top: 15px;
    text-align: justify;
    font-size: 17px;
  }

  .certificate-note p {
    font-size: 17px;
  }

  .trade-certificate-note {
    line-height: normal;
    margin-top: 4px;
    text-align: justify;
    font-size: 17px;
    div::first-letter {
      padding-left: 42px;
    }
  }

  .print-area {
    border: 2px solid #000000 !important;
    /* height: 1020px !important; */
    padding: 50px;
    height: 100%;
  }

  .print-border {
    height: 100%;
    padding: 12px;
    border: 2px solid #000000;
    /* padding: 50px; */
  }

  .not-approve {
    display: block;
  }
}
